/* RESPONSIBLE TEAM: team-frontend-tech */
import Application from '@ember/application';
import Resolver from './resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'embercom/config/environment';
import registerRawHelpers from 'embercom/helpers-raw/register';
import './lib/custom-inflector-rules';
import initializeSentry, { captureException } from 'embercom/lib/sentry';
import ENV from 'embercom/config/environment';
import '@glint/environment-ember-loose';

// global import of codemirror styles - formerly lived in app/components/message-editor/content/html-mode/text-area.js
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/theme/panda-syntax.css';
import 'codemirror/theme/neo.css';
import 'codemirror/addon/lint/lint.css';

// add default error handling before Sentry setup
if (ENV.environment !== 'test') {
  Ember.onerror = (error) => {
    captureException(error);
    return console.error(error);
  };
}

initializeSentry();

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
  customEvents = {
    copy: 'copy',
    paste: 'paste',
    cut: 'cut',
    scroll: 'scroll',
    wheel: 'wheel',
  };

  ready() {
    document.documentElement.querySelector('body').classList.add('ember-ready');
  }
}

loadInitializers(App, config.modulePrefix);
registerRawHelpers();
